.refuse {
  border-color: #d00;
  color: #d00;
}

.refuse:hover {
  background-color: transparent;
}

.documents-container-width {
  width: calc(50% - 1.5rem) !important;
}

.profile-details--width {
  width: calc(50% - 1.5rem);
  align-items: center;
}

.client-details--title {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.document-container {
  display: flex;
  justify-content: flex-end;
  gap: 1.5rem;
  flex-wrap: wrap;
}

.view-button {
  display: flex;
}

.modal-label {
  width: calc(50% - 1.5rem);
  color: #5e5873;
  font-weight: 600;
  font-size: 0.9rem;
}

.ant-btn > span {
  display: flex;
}

.ant-modal {
  width: auto !important;
}

.ant-modal-body > * {
  display: flex;
  margin: auto;
  max-width: 100%;
  max-height: 70vh;
}

.ant-modal-title {
  width: 95%;
  font-size: 15px !important;
}

.document-container--width {
  width: calc(50% - 1.5rem);
}

.iframe-pdf {
  width: 70vw;
  height: 70vh;
}

.textarea-red {
  border: 1px solid #d00 !important;
  box-shadow: rgba(221, 0, 0, 0.2) 0px 2px 8px 0px;
  transition: all 0.2s;
}

.refuse-modal {
  width: 50% !important;
}

.aprove-spin span i {
  background-color: #fff !important;
}

.input-editable {
  border-color: var(--primaryColor);
  box-shadow: 0 3px 10px 0 rgba(34, 41, 47, 0.1);
  transition: all 0.4s;
}

.country-border div {
  border-color: var(--primaryColor) !important;
  box-shadow: 0 3px 10px 0 rgba(34, 41, 47, 0.1);
  transition: border, box-shadow 0.4s;
}

.input-empty-red.ant-select:not(.ant-select-customize-input)
  .ant-select-selector,
.input-empty-red {
  border-color: #d00;
  box-shadow: rgba(221, 0, 0, 0.2) 0px 2px 8px 0px;
  transition: all 0.4s;
}

.clients-details .form-input {
  margin-bottom: auto;
}

.client-comment.input-editable:focus,
.client-comment.input-editable:hover {
  border-color: var(--primaryColor);
}

@media (max-width: 1200px) {
  .modal-label {
    font-size: 13px;
  }
  .view-button {
    font-size: 13px !important;
    align-items: center;
  }
}

@media (max-width: 576px) {
  .modal-label {
    width: 100%;
    text-align: center;
  }
  .document-container {
    gap: 1rem;
    justify-content: center;
  }
  .documents-container-width {
    width: 100% !important;
  }
}
