.back-to-login__button {
  display: flex;
  align-items: center;
  gap: 7px;
  color: var(--primaryColor);
  font-size: 1rem;
  justify-content: center;
  text-decoration: none;
}

@media (max-width: 576px) {
  .forgot-pw--text {
    width: 90%;
    margin: auto;
    font-size: 13px;
  }

  .input-error--width {
    width: 90%;
    margin: auto;
  }

  .back-to-login__button {
    width: 90%;
    margin: auto;
    font-size: 13px;
  }
}
