.cars-button,
.cars-button:active {
  color: #17a2b8;
  border-color: #17a2b8;
}

.cars-button:hover {
  color: #17a2b8 !important ;
  border-color: #17a2b8 !important;
}

.ant-pagination {
  display: flex;
  justify-content: center;
  margin-top: 1.5rem;
}

.search-content--width {
  width: 50%;
}

.search-content--width.cars {
  width: unset;
  margin-top: 1.5rem;
}

.not-verified-bg {
  color: rgb(23, 162, 184);
  background-color: rgba(23, 162, 184, 0.12) !important;
}

.approved-bg {
  color: rgb(40, 199, 111);
  background-color: rgba(40, 199, 111, 0.12) !important;
}

.refused-bg {
  color: rgb(220, 53, 69);
  background-color: rgba(220, 53, 69, 0.12) !important;
}

.pending-bg {
  color: rgb(255, 159, 67);
  background-color: rgb(255, 159, 67, 0.12) !important;
}

@media (max-width: 1200px) {
  .upper-mid-with {
    width: 20% !important;
  }

  .th__width {
    width: auto !important;
  }

  .mid-width {
    width: 17% !important;
  }

  .min-width {
    width: 8% !important;
  }

  .search-content--width {
    width: 75%;
  }
}

@media (max-width: 992px) {
  @media (max-width: 992px) {
    .action-button,
    .cars-button {
      font-size: 0.8rem;
    }
  }

  .search-content--width {
    width: 80%;
  }
  .ant-pagination {
    font-size: 0.7rem;
  }
}

@media (max-width: 768px) {
  .search-content--width {
    width: 100%;
  }
}

@media (max-width: 576px) {
  .search-content--width {
    margin: auto;
    margin-bottom: 2rem;
    gap: 1.5rem;
  }

  .search-button {
    width: 100%;
    justify-content: center;
  }
}
