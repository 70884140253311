.flag-icon {
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: contain;
  display: inline-block;
  line-height: 1em;
  position: relative;
  width: 1.3333333333em;
}

.flag-icon:before {
  content: " ";
}

.flag-icon.flag-icon-squared {
  width: 1em;
}

.flag-icon-be {
  background-image: url(/src/assets/images/flagicons/be.svg);
}

.flag-icon-be.flag-icon-squared {
  background-image: url(/src/assets/images/flagicons/be.svg);
}

.flag-icon-br {
  background-image: url(/src/assets/images/flagicons/br.svg);
}

.flag-icon-br.flag-icon-squared {
  background-image: url(/src/assets/images/flagicons/br.svg);
}

.flag-icon-ch {
  background-image: url(/src/assets/images/flagicons/ch.svg);
}

.flag-icon-ch.flag-icon-squared {
  background-image: url(/src/assets/images/flagicons/ch.svg);
}

.flag-icon-cl {
  background-image: url(/src/assets/images/flagicons/cl.svg);
}

.flag-icon-cl.flag-icon-squared {
  background-image: url(/src/assets/images/flagicons/cl.svg);
}

.flag-icon-cn {
  background-image: url(/src/assets/images/flagicons/cn.svg);
}

.flag-icon-cn.flag-icon-squared {
  background-image: url(/src/assets/images/flagicons/cn.svg);
}

.flag-icon-co {
  background-image: url(/src/assets/images/flagicons/co.svg);
}

.flag-icon-co.flag-icon-squared {
  background-image: url(/src/assets/images/flagicons/co.svg);
}

.flag-icon-cz {
  background-image: url(/src/assets/images/flagicons/cz.svg);
}

.flag-icon-cz.flag-icon-squared {
  background-image: url(/src/assets/images/flagicons/cz.svg);
}

.flag-icon-de {
  background-image: url(/src/assets/images/flagicons/de.svg);
}

.flag-icon-de.flag-icon-squared {
  background-image: url(/src/assets/images/flagicons/de.svg);
}

.flag-icon-dk {
  background-image: url(/src/assets/images/flagicons/dk.svg);
}

.flag-icon-dk.flag-icon-squared {
  background-image: url(/src/assets/images/flagicons/dk.svg);
}

.flag-icon-es {
  background-image: url(/src/assets/images/flagicons/es.svg);
}

.flag-icon-es.flag-icon-squared {
  background-image: url(/src/assets/images/flagicons/es.svg);
}

.flag-icon-fi {
  background-image: url(/src/assets/images/flagicons/fi.svg);
}

.flag-icon-fi.flag-icon-squared {
  background-image: url(/src/assets/images/flagicons/fi.svg);
}

.flag-icon-fr {
  background-image: url(/src/assets/images/flagicons/fr.svg);
}

.flag-icon-fr.flag-icon-squared {
  background-image: url(/src/assets/images/flagicons/fr.svg);
}

.flag-icon-gb {
  background-image: url(/src/assets/images/flagicons/gb.svg);
}

.flag-icon-gb.flag-icon-squared {
  background-image: url(/src/assets/images/flagicons/gb.svg);
}

.flag-icon-gr {
  background-image: url(/src/assets/images/flagicons/gr.svg);
}

.flag-icon-gr.flag-icon-squared {
  background-image: url(/src/assets/images/flagicons/gr.svg);
}

.flag-icon-hu {
  background-image: url(/src/assets/images/flagicons/hu.svg);
}

.flag-icon-hu.flag-icon-squared {
  background-image: url(/src/assets/images/flagicons/hu.svg);
}

.flag-icon-in {
  background-image: url(/src/assets/images/flagicons/in.svg);
}

.flag-icon-in.flag-icon-squared {
  background-image: url(/src/assets/images/flagicons/in.svg);
}

.flag-icon-it {
  background-image: url(/src/assets/images/flagicons/it.svg);
}

.flag-icon-it.flag-icon-squared {
  background-image: url(/src/assets/images/flagicons/it.svg);
}

.flag-icon-lu {
  background-image: url(/src/assets/images/flagicons/lu.svg);
}

.flag-icon-lu.flag-icon-squared {
  background-image: url(/src/assets/images/flagicons/lu.svg);
}

.flag-icon-ma {
  background-image: url(/src/assets/images/flagicons/ma.svg);
}

.flag-icon-ma.flag-icon-squared {
  background-image: url(/src/assets/images/flagicons/ma.svg);
}

.flag-icon-nl {
  background-image: url(/src/assets/images/flagicons/nl.svg);
}

.flag-icon-nl.flag-icon-squared {
  background-image: url(/src/assets/images/flagicons/nl.svg);
}

.flag-icon-no {
  background-image: url(/src/assets/images/flagicons/no.svg);
}

.flag-icon-no.flag-icon-squared {
  background-image: url(/src/assets/images/flagicons/no.svg);
}

.flag-icon-pe {
  background-image: url(/src/assets/images/flagicons/pe.svg);
}

.flag-icon-pe.flag-icon-squared {
  background-image: url(/src/assets/images/flagicons/pe.svg);
}

.flag-icon-pl {
  background-image: url(/src/assets/images/flagicons/pl.svg);
}

.flag-icon-pl.flag-icon-squared {
  background-image: url(/src/assets/images/flagicons/pl.svg);
}

.flag-icon-pt {
  background-image: url(/src/assets/images/flagicons/pt.svg);
}

.flag-icon-pt.flag-icon-squared {
  background-image: url(/src/assets/images/flagicons/pt.svg);
}

.flag-icon-ro {
  background-image: url(/src/assets/images/flagicons/ro.svg);
}

.flag-icon-ro.flag-icon-squared {
  background-image: url(/src/assets/images/flagicons/ro.svg);
}

.flag-icon-ru {
  background-image: url(/src/assets/images/flagicons/ru.svg);
}

.flag-icon-ru.flag-icon-squared {
  background-image: url(/src/assets/images/flagicons/ru.svg);
}

.flag-icon-se {
  background-image: url(/src/assets/images/flagicons/se.svg);
}

.flag-icon-se.flag-icon-squared {
  background-image: url(/src/assets/images/flagicons/se.svg);
}

.flag-icon-sk {
  background-image: url(/src/assets/images/flagicons/sk.svg);
}

.flag-icon-sk.flag-icon-squared {
  background-image: url(/src/assets/images/flagicons/sk.svg);
}

.flag-icon-tr {
  background-image: url(/src/assets/images/flagicons/tr.svg);
}

.flag-icon-tr.flag-icon-squared {
  background-image: url(/src/assets/images/flagicons/tr.svg);
}

.flag-icon-uk {
  background-image: url(/src/assets/images/flagicons/gb.svg);
}

.flag-icon-uk.flag-icon-squared {
  background-image: url(/src/assets/images/flagicons/gb.svg);
}

.flag-icon:before {
  content: " ";
  white-space: pre;
}
