.services__checkboxes {
  justify-content: flex-start;
}

.input-red {
  border: 1px solid #d00;
  border: 1px solid #d00;
  border-color: #d00;
  box-shadow: rgba(221, 0, 0, 0.2) 0px 2px 8px 0px;
  transition: all 0.4s;
}

.ant-select-single:not(.ant-select-customize-input)
  .ant-select-selector
  .ant-select-selection-search-input {
  height: 100%;
}

@media (max-width: 992px) {
  .services__checkboxes {
    flex-direction: column;
    gap: 0.5rem;
    align-items: self-start;
  }
  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    height: 41.25px !important;
  }
}

@media (max-width: 576px) {
  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    height: 39.09px !important;
  }

  .ant-select-single.ant-select-show-arrow .ant-select-selection-item,
  .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
    font-size: 13px;
  }
}
