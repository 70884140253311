.arval-content {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding: 1.5% 1.5% 3%;
}

.arval-content__item {
  display: flex;
  align-items: center;
}

.arval-content__item span {
  width: 20%;
  line-height: 1.5;
}
.arval-content__item div {
  width: 80%;
}

@media (max-width: 800px) {
  .arval-content__item {
    flex-direction: column;
    align-items: inherit;
  }
  .arval-content__item span {
    width: 100%;
  }

  .arval-content__item div {
    width: 100%;
  }
}

@media (max-width: 576px) {
  .arval-content__item {
    padding: 0 5%;
  }

  .arval-btn {
    margin: 0 5%;
    margin-left: auto;
  }
}
