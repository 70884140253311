.login-bg {
  width: 100%;
  height: 100vh;
  background: #f8f8f8;
  display: flex;
  justify-content: center;
  align-items: center;
}

.login {
  max-width: 30rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1.53rem;
  padding: 1.5%;
  background: #fff;
  box-shadow: 0 4px 24px 0 rgba(34, 41, 47, 0.1);
}

.login__company {
  color: var(--primaryColor);
  font-weight: 600;
  font-size: 1.714rem;
  text-align: center;
}

.login__title {
  font-weight: 500;
  font-size: 1.285rem;
}

.login__paragraph {
  font-size: 1rem;
  font-weight: 400;
  color: #6e6b7b;
}

.login__inputs {
  display: flex;
  flex-direction: column;
  gap: 1.53rem;
}

.login__checkbox {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.login__chekbox__forgot-password {
  text-decoration: none;
  color: var(--primaryColor);
  font-size: 0.857rem;
  margin-left: auto;
}

.login__button {
  background: var(--primaryColor);
  color: #fff;
  border: 1px solid var(--primaryColor);
  outline: none;
  font-family: inherit;
  font-size: 1rem;
  padding: 0.786rem 1.5rem;
  border-radius: 0.358rem;
  line-height: 1;
  cursor: pointer;
  transition: all 0.15s ease-in-out;
}

.login__button:hover {
  box-shadow: 0 8px 25px -8px var(--primaryColor);
  transition: all 0.15s ease-in-out;
}

.login__button:active {
  box-shadow: none;
}

.login__new-on-platform {
  text-align: center;
  color: #6e6b7b;
}

.create-account__link {
  text-decoration: none;
  color: var(--primaryColor);
}

.login__or {
  position: relative;
  text-align: center;
}

.login__or::after {
  content: "";
  height: 1px;
  width: 45%;
  background: #ebe9f1;
  position: absolute;
  top: 9px;
  left: 0px;
}
.login__or::before {
  content: "";
  height: 1px;
  width: 45%;
  background: #ebe9f1;
  position: absolute;
  top: 9px;
  right: 0px;
}

.login__social-links {
  display: flex;
  justify-content: center;
  gap: 1.5rem;
}

.fb-link {
  stroke: #1877f2;
  fill: #1877f2;
  font-size: 23px;
}

.twitter-link {
  stroke: #1da1f2;
  fill: #1da1f2;
  font-size: 23px;
}
.gmail-link {
  stroke: #c71610;
  fill: #c71610;
  font-size: 23px;
}
.github-link {
  stroke: #171515;
  fill: #171515;
  font-size: 23px;
}

.error-input {
  border: 1px solid #d00;
  box-shadow: 0 3px 10px 0 rgba(221, 0, 0, 0.1);
}

.ant-select.error-input {
  border-radius: 6px;
}

.ant-spin {
  color: #fff;
}

.client-table .ant-spin {
  color: gray;
}

@media (max-width: 576px) {
  .login {
    text-align: center;
    margin: 5%;
    padding: 8% 0;
  }

  .login__title {
    font-size: 1rem;
  }

  .login__paragraph {
    width: 90%;
    margin: auto;
    font-size: 13px;
  }

  .login__inputs {
    width: 90%;
    margin: auto;
    gap: 1.5rem;
  }

  .login__checkbox {
    width: 90%;
    margin: auto;
  }

  .ant-checkbox + span {
    font-size: 12px;
  }

  .login__chekbox__forgot-password {
    font-size: 12px;
  }

  .login__button {
    width: 90%;
    margin: auto;
    font-size: 13px;
  }

  .error-message {
    text-align: initial;
  }
}
