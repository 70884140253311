._-LJ2W {
  gap: 10px;
  cursor: default;
  justify-content: center;
}

._t1897 {
  cursor: -webkit-grab;
  cursor: grab;
}

._1_Dg2,
._3q7r8 {
  z-index: 97;
  background-color: rgba(0, 0, 0, 0.6);
  gap: 3rem;
  padding: 3%;
}

.gallery-carousel__button {
  position: fixed;
  top: 10px;
  right: 10px;
  z-index: var(--carousel-z-index);
  font-size: 50px;
  color: white;
  background: none;
  border: none;
  outline: none;
  cursor: pointer;
}

.galleryCarousel {
  height: 28rem;
  width: 100%;
  background-color: transparent;
  gap: 10px;
}

.carousel-gallery {
  z-index: var(--carousel-z-index);
}

@media (max-width: 1000px) {
  .galleryCarousel {
    height: 34rem;
  }
}
@media (max-width: 800px) {
  .galleryCarousel {
    height: 27rem;
  }
}

@media (max-width: 600px) {
  .galleryCarousel {
    height: 20rem;
  }
}
