.checkbox-container {
  display: flex;
  gap: 3rem;
  row-gap: 1.5rem;
  flex-wrap: wrap;
}

@media (max-width: 1200px) {
  .checkbox-container {
    gap: 1.7rem;
  }

  .ant-checkbox-wrapper {
    font-size: 0.7rem;
  }

  .ant-radio-wrapper {
    font-size: 0.7rem;
    align-items: center;
  }

  .ant-radio-wrapper .ant-radio-inner {
    width: 14px;
    height: 14px;
  }
}

@media (max-width: 992px) {
  .checkbox-container {
    flex-direction: column;
    gap: 0.5rem;
  }

  .ant-checkbox-wrapper {
    line-height: 2.012;
  }
}
