.pdf-icon {
  font-size: 25px;
  cursor: pointer;
}

.pdf-icon svg {
  color: #f40f02;
}

.image-icon {
  font-size: 25px;
  cursor: pointer;
}

.image-icon svg{
  color: #336699;
}
