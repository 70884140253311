.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  height: 2.713rem;
  align-items: center;
}

.ant-select {
  width: 100%;
}

.ant-select-single .ant-select-selector {
  color: #6e6b7b;
}

.ant-select-dropdown .ant-select-item {
  font-size: 1rem;
  color: #6e6b7b;
}

.ant-select-disabled:where(
    .css-dev-only-do-not-override-15rg2km
  ).ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  border-color: #d9d9d9;
  box-shadow: none;
  transition: none;
}

.ant-select-selection-item {
  font-size: 1rem;
}

.ant-select-arrow {
  margin: auto;
}

.country-label {
  font-size: 0.857rem;
  color: #5e5873;
}

.country-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 5px;
}

.ant-select-single:not(.ant-select-customize-input)
  .ant-select-selector
  .ant-select-selection-search-input {
  height: 100%;
}

@media (max-width: 1200px) {
  .ant-select-selector {
    height: 43.4px !important;
  }
}

@media (max-width: 992px) {
  .ant-select-selector {
    height: 36.02px !important;
  }
}
