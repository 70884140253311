.layout {
  display: flex;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 999;
}

.layout__menu {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  background: #fff;
  width: 16.25rem;
  height: 100vh;
  box-shadow: 0 0 15px 0 rgba(34, 41, 47, 0.05);
}

.layout__menu h2 {
  color: var(--primaryColor);
  font-weight: 800;
  font-size: 1.714rem;
  text-align: center;
  padding: 10%;
  letter-spacing: 1px;
  -webkit-border-end: 1px solid rgba(5, 5, 5, 0.06);
  border-inline-end: 1px solid rgba(5, 5, 5, 0.06);
}

.layout__header {
  background: #fff;
  width: 100%;
  height: 4.45rem;
  display: flex;
  box-shadow: 0 4px 24px 0 rgba(34, 41, 47, 0.1);
  align-items: center;
  margin: 1% 3%;
  padding: 0 1.5%;
  border-radius: 0.428rem;
}

.layout__avatar-container {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-left: auto;
}

.layout__user-name {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  color: #6e6b7b;
  gap: 2px;
  font-size: 13px;
}

.ant-menu-light,
.ant-menu-light > .ant-menu {
  color: #6e6b7b;
}
.ant-menu-light .ant-menu-submenu-selected > .ant-menu-submenu-title,
.ant-menu-light
  > .ant-menu
  .ant-menu-submenu-selected
  > .ant-menu-submenu-title {
  color: var(--primaryColor);
  font-weight: 500;
}

.ant-menu-item-selected.inActive {
  color: rgba(0, 0, 0, 0.88) !important;
  background: transparent !important;
  box-shadow: none !important;
}

.ant-menu-item-selected.inActive:hover {
  background-color: rgba(0, 0, 0, 0.06) !important;
}

.ant-menu-light .ant-menu-item-selected,
.ant-menu-light > .ant-menu .ant-menu-item-selected {
  background-color: var(--primaryColor);
  box-shadow: 0 0 10px 1px var(--primaryColor);
}

.ant-menu-light .ant-menu-item-selected,
.ant-menu-light > .ant-menu .ant-menu-item-selected {
  color: #fff;
}

.ant-menu-inline.ant-menu-root .ant-menu-item > .ant-menu-title-content,
.ant-menu-inline.ant-menu-root
  .ant-menu-submenu-title
  > .ant-menu-title-content {
  font-size: 1.1rem;
}

.ant-menu-inline.ant-menu-root .ant-menu-item > *,
.ant-menu-inline.ant-menu-root .ant-menu-submenu-title > * {
  font-size: 1.1rem;
}

.ant-menu-light:not(.ant-menu-horizontal) .ant-menu-submenu-title:hover,
.ant-menu-light
  > .ant-menu:not(.ant-menu-horizontal)
  .ant-menu-submenu-title:hover {
  padding-left: 30px !important;
}

.profile-link {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 1rem;
  text-decoration: none;
}

.profile-link--logout {
  color: #d00 !important;
}

.ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-item:hover,
.ant-dropdown-menu-submenu .ant-dropdown-menu .ant-dropdown-menu-item:hover,
.ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-submenu-title:hover,
.ant-dropdown-menu-submenu
  .ant-dropdown-menu
  .ant-dropdown-menu-submenu-title:hover,
.ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-item-active,
.ant-dropdown-menu-submenu .ant-dropdown-menu .ant-dropdown-menu-item-active,
.ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-submenu-title-active,
.ant-dropdown-menu-submenu
  .ant-dropdown-menu
  .ant-dropdown-menu-submenu-title-active {
  background-color: rgba(51, 104, 153, 0.04);
}

.ant-dropdown .ant-dropdown-menu,
.ant-dropdown-menu-submenu .ant-dropdown-menu {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

img,
svg {
  vertical-align: baseline !important;
}

.close__button {
  display: none;
  font-size: 18px;
  font-weight: 500;
  cursor: pointer;
}

.layout__trigger {
  display: none;
}

@media (max-width: 1200px) {
  .layout__menu {
    display: none;
  }

  .ant-drawer .ant-drawer-body {
    padding: 0;
  }

  .ant-drawer-left > .ant-drawer-content-wrapper {
    width: 16.25rem !important;
  }

  .ant-drawer .ant-drawer-content {
    height: auto;
  }

  .close__button {
    display: block;
  }
  .ant-drawer .ant-drawer-header {
    display: none;
  }

  .layout__menu h2 {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 11%;
  }
  .layout__trigger {
    display: block;
    font-size: 20px;
  }
}

@media (max-width: 992px) {
  .layout__menu {
    width: 14rem;
  }

  .layout__menu h2 {
    font-size: 1.2rem;
  }

  .ant-drawer-left > .ant-drawer-content-wrapper {
    width: 14rem !important;
  }

  .ant-menu-inline.ant-menu-root .ant-menu-item > *,
  .ant-menu-inline.ant-menu-root .ant-menu-submenu-title > * {
    font-size: 0.9rem;
  }
}

@media (max-width: 576px) {
  .layout__header {
    padding: 0 2.5%;
  }

  .ant-menu-inline.ant-menu-root .ant-menu-item > *,
  .ant-menu-inline.ant-menu-root .ant-menu-submenu-title > * {
    font-size: 15.5px !important;
  }
}
