.week-stats {
  display: flex;
  justify-content: space-between;
}

@media (max-width: 1200px) {
  .week-stats {
    flex-direction: column;
    gap: 4rem;
  }
}
