.table-responsive {
  background: #fff;
  box-shadow: 0 4px 24px 0 rgba(34, 41, 47, 0.1);
  border: 1px solid rgb(222, 226, 230);
}

.table-titles {
  color: #6e6b7b;
  font-weight: 500;
  background: #f3f2f7;
}
.table {
  margin: 0;
}

.table > :not(caption) > * > * {
  /* padding: 0.7% 1.5%; */
  padding: 1%;
  border-right: 1px solid rgb(222, 226, 230);
  border-bottom: 1px solid rgb(222, 226, 230);
  min-width: 50px;
}

thead,
tbody,
tfoot,
tr,
td,
th {
  color: #6e6b7b;
}

.dropdown-item {
  padding: 0.55rem 1.5rem;
  display: flex;
  gap: 6px;
}

.action-content {
  display: flex;
  gap: 1rem;
}

.action-button {
  display: flex;
  align-items: center;
  gap: 5px;
}

.edit {
  color: #0958d9;
  border-color: #0958d9;
}

.warning,
.warning:hover,
.warning:active {
  color: #d00 !important;
  border-color: #d00 !important;
}

.search-content {
  display: flex;
  align-items: flex-end;
  width: 50%;
  margin: 0 0 2rem auto;
}

.search-button {
  display: flex;
  align-items: center;
  height: 43.4px;
  padding: 0 22px;
  background: var(--primaryColor);
  color: #fff;
  font-weight: 500;
  border-color: var(--primaryColor);
  font-size: 15px;
}

.search-button:hover {
  color: #fff !important;
  border-color: var(--primaryColor) !important;
}

.active-bg {
  background: rgba(40, 199, 111, 0.12) !important;
  color: #28c76f;
}

.passive-bg {
  background: rgba(130, 134, 139, 0.12) !important;
  color: #82868b;
}

.userList-actions {
  width: 18%;
}

@media (max-width: 1200px) {
  .ant-select-selection-item {
    font-size: 0.8rem;
  }

  .ant-select-item-option-content {
    font-size: 13px;
  }
  .search-content {
    width: 60%;
  }

  .search-button {
    height: 38.8px;
  }
}

@media (max-width: 1000px) {
  .action-content {
    gap: 10px;
  }

  .action-button {
    padding: 5%;
  }
}

@media (max-width: 992px) {
  .search-content {
    gap: 2rem;
  }
}

@media (max-width: 992px) {
  .search-button {
    height: 36.02px;
  }
}

@media (max-width: 576px) {
  .search-content {
    gap: 1.5rem;
    margin: auto auto 2rem;
  }
}
