.header-outlet__content {
  width: calc(100% - 16.25rem);
  height: 100dvh;
  -moz-height: 100vh;
  display: flex;
  flex-direction: column;
}

.outlet {
  width: 100%;
  height: 100%;
  padding: 1% 3%;
  overflow: auto;
}

@media (max-width: 1200px) {
  .header-outlet__content {
    width: 100%;
  }
}
