.done-icon {
  font-size: 20px;
  fill: #00a300;
  stroke: #00a300;
}

.not-icon {
  font-size: 20px;
  fill: #d00;
  stroke: #d00;
}

.add-services__button {
  display: flex;
  margin: 1.5rem auto 0;
  width: 30%;
  padding: 1.5%;
  align-items: center;
  justify-content: center;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  transition: all 0.15s ease-in-out;
  font-size: 15px;
}

.add-services__button:hover {
  box-shadow: none;
  transition: all 0.15s ease-in-out;
}

.country-flag {
  display: flex;
  gap: 10px;
  align-items: center;
}

.thh__width {
  width: 12% !important;
}

.action-dropdown {
  display: none;
}

@media (max-width: 1200px) {

  .action-dropdown {
    display: block;
  }
  .tr-fontSize {
    font-size: 15px;
  }

  .done-icon {
    font-size: 15px;
  }

  .not-icon {
    font-size: 15px;
  }

  .table-titles {
    font-size: 14px;
  }
  .dropdown-item:hover {
    color: #0958d9;
  }

  .ant-btn {
    font-size: 13px;
  }
}

@media (max-width: 992px) {
  .table-titles {
    font-size: 12px;
  }

  .tr-fontSize {
    font-size: 13px;
  }
}

@media (max-width: 576px) {
  .add-services__button {
    width: 35%;
  }
}
