.car-details-bg {
  background: #eff2f4;
}
.car-details-container {
  width: 100%;
  /* margin: 3rem auto; */
  /* box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px; */
  transition: all 0.3s;
}

.car-details-container:hover {
  /* box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 1px 3px 1px; */
  transition: all 0.3s;
}

.car-details-container__header {
  padding: 2%;
  background: #ebebeb;
}

.car-details-container__header p {
  display: flex;
  align-items: center;
  gap: 7px;
  font-weight: 500;
}

.car-details {
  display: flex;
  gap: 5rem;
  background: #fff;
  padding: 2%;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.car-details-title {
  width: 50%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.car-details-title__ids {
  display: flex;
  gap: 2rem;
}

.car-details-title h2 {
  color: #12100c;
  font-size: 21px;
  font-weight: 600;
}

.car-details-info {
  width: 50%;
}

.car-details-title__img img {
  width: 100%;
}

.car-details-info__inputAndButton {
  display: flex;
  flex-direction: row;
  gap: 5px;
  margin-bottom: 2rem;
}

.car-details-label {
  display: flex;
  flex-direction: column;
}

.car-details-label__text {
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  color: #12100c;
}

.car-details-info__inputAndButton input {
  /* background-image: url("../../../assets/images/euro.png"); */
  background-size: 18px;
  background-position: 8px;
  background-repeat: no-repeat;
  height: 30px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  outline: none;
  padding-left: 37px;
  font-size: 20px;
}

.car-details-info__inputAndButton button {
  background: #28a745;
  color: white;
  border: none;
  outline: none;
  height: 30px;
  cursor: pointer;
  border-radius: 2px;
  font-size: 15px;
  font-family: "Montserrat";
}

.car-details-info-about-car {
  display: flex;
  flex-direction: column;
}

.car-details-info-about-car__content {
  display: flex;
  gap: 1rem;
  padding: 3%;
}

.car-details-info-about-car__content span {
  width: 50%;
  font-size: 14px;
}

.car-details-info-about-car__content-line {
  width: 100%;
  height: 1px;
  background: rgba(0, 0, 0, 0.2);
  margin: auto;
}

.car-details-fee {
  display: flex;
  flex-direction: column;
  margin: 2rem auto;
}

.car-details-fee__title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #ebebeb;
  padding: 3%;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s;
}

.car-details-fee__title:hover {
  background: #cecece;
  transition: all 0.3s;
}

.car-details-fee__container {
  background: #f5fafe;
  display: flex;
  flex-direction: column;
}

.car-details-fee__container p {
  padding: 3%;
  font-weight: 500;
}

.car-details-fee__content {
  display: flex;
  justify-content: space-between;
  padding: 3%;
  font-weight: 500;
}

.car-details-fee__line {
  width: 95%;
  height: 1px;
  background: #959595;
  margin: 10px auto;
}

.fee-content__total {
  margin-left: auto;
  gap: 1.5rem;
}

.grid-control {
  display: grid;
  grid-template-rows: 0fr;
  transition: grid-template-rows 0.4s ease;
  transition: grid-template-rows 0.4s ease, -ms-grid-rows 0.4s ease;
}

.grid-control .car-details-fee__container {
  overflow: hidden;
}

.grid-expand {
  grid-template-rows: 1fr;
}

.car-maintance-history {
  padding: 3rem;
  background-color: #fff;
  margin-top: 1rem;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.car-maintance-history table {
  width: 100%;
}

.car-damages,
.car--details {
  background-color: #fff;
  margin-top: 1rem;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.max-bid-amount {
  font-weight: 600;
  font-size: 14px;
  text-align: end;
  padding: 0.4rem;
  width: -moz-fit-content;
  width: fit-content;
  margin-left: auto;
  color: #e90f0f;
  display: flex;
  gap: 0.2rem;
}

.error-input .ant-input.ant-input-lg {
  color: red;
}

.inline-links {
  display: flex;
  flex-wrap: wrap;
  padding: 0 1rem;
  justify-content: space-evenly;
  gap: 0.6rem 0.4rem;
}

.inline-links a {
  text-decoration: underline;
}

.inline-links span {
  display: flex;
  align-items: center;
}

.container.details-skeleton-containers .auction-list__content {
  box-shadow: unset;
  border: 0;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.container.details-skeleton-containers
  .auction-list__content
  .ant-skeleton-button {
  width: 450px !important;
  height: 200px !important;
}

.container.details-skeleton-containers .auction-list__content > div {
  box-shadow: unset;
  border: 0;
  display: flex;
  gap: 0 3.5rem;
}

div.vat-excluded {
  font-size: 13px;
  font-weight: 700;
  color: #3b3939d4;
  border-bottom: 2px solid #8080806e;
  padding-bottom: 0.2rem;
  text-align: end;
}

.starting-price {
  font-size: 11.5px;
  padding: 0.4rem;
  display: flex;
  gap: 0.4rem;
  color: #0e0d0d;
  flex-direction: column;
  text-align: start;
  font-weight: 500;
}

.starting-price span {
  font-weight: 500;
  font-size: 22px;
}

@media (max-width: 1000px) {
  .car-details-container__header {
    padding: 2% 5%;
  }
  .car-details {
    flex-wrap: wrap;
    padding: 3% 5%;
    gap: 4rem;
  }

  .car-details-title {
    width: 100%;
    gap: 1.7rem;
  }

  .car-details-info {
    width: 100%;
  }

  .car-details-fee__title {
    padding: 2%;
  }
  .car-details-info__inputAndButton button {
    height: 35px;
    font-size: 17px;
  }
}
@media (max-width: 800px) {
  .car-details-title__ids {
    gap: 1rem;
  }
}

@media (max-width: 600px) {
  .car-details {
    gap: 3rem;
  }

  .car-details-info__inputAndButton input {
    background-size: 13px;
    font-size: 15px;
  }

  .car-details-title {
    gap: 1rem;
  }
  .car-details-title h2 {
    font-size: 17px;
  }
  .car-details-info__inputAndButton button {
    font-size: 15px;
  }
  .car-details-container__header p {
    font-size: 13px;
  }
  .car-details-title__ids span {
    font-size: 13px;
  }
  .car-details-fee__title {
    font-size: 13px;
  }
  .grid-control .car-details-fee__container {
    font-size: 12px;
  }
  .car-details-info-about-car__content span {
    font-size: 13px;
  }
}

@media (max-width: 400px) {
  .car-details-info-about-car__content {
    flex-direction: column;
  }
  .car-details-info-about-car__content span {
    width: 100%;
  }
  .details-title {
    font-weight: 500;
  }
  .width-fix {
    width: 80%;
  }
}
