html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  box-sizing: border-box;
  font-family: "Montserrat", sans-serif;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
  background: #f8f8f8;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

@-webkit-keyframes moveInBottom {
  0% {
    opacity: 0;
    transform: translateY(-0.3rem);
  }

  100% {
    opacity: 1;
    transform: translate(0);
  }
}

@keyframes moveInBottom {
  0% {
    opacity: 0;
    transform: translateY(-0.3rem);
  }

  100% {
    opacity: 1;
    transform: translate(0);
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  vertical-align: middle;
}

.table {
  width: 1558px !important;
  font-size: 0.84rem;
}

.table-responsive {
  width: 100%;
}

.page-not-found__error {
  height: 100vh;
  color: #d00;
  font-size: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 300;
  flex-direction: column;
}

.page-not-found__text {
  display: flex;
  align-items: center;
  gap: 10px;
}


.ant-spin-nested-loading{
  width: 100%;
}

.icon-404 {
  font-size: 7rem;
}

.bid-history-modal {
  width: 800px !important;
}

.page-not-found__button {
  background: transparent;
  border: 1px solid #d00;
  border-radius: 3px;
  padding: 1% 1.5%;
  color: #d00;
  font-family: unset;
  font-size: 15px;
  outline: none;
  transition: all 0.4s;
  cursor: pointer;
}

.page-not-found__button:hover {
  color: white;
  background-color: #d00;
  transition: all 0.4s;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-transition: background-color 9999s ease-in-out 0s;
  transition: background-color 9999s ease-in-out 0s;
}

::-webkit-scrollbar {
  width: 20px;
  height: 20px;
}

::-webkit-scrollbar-track {
  background-color: #fff;
}

::-webkit-scrollbar-thumb {
  background-color: #d6dee1;
  border-radius: 20px;
  border: 6px solid transparent;
  background-clip: content-box;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #a8bbbf;
}

@media (max-width: 1200px) {
  .table {
    width: 1300px;
  }

  @media (max-width: 992px) {
    .table {
      width: 1200px;
    }
  }
}

@media (max-width: 576px) {
  .table-responsive {
    width: 90%;
    margin: auto;
  }

  .page-not-found__button {
    padding: 3% 3.5%;
  }
}

@media (max-width: 380px) {
  .page-not-found__text {
    flex-direction: column;
    margin-bottom: 2rem;
  }
}
