.received-cars-list {
  width: 100%;
  display: flex;
  justify-content: space-between;
  background: var(--primaryColor);
  color: white;
  padding: 1%;
}

.wining-car-section {
  margin: auto;
  display: flex;
  align-items: center;
  width: 100%;
  gap: 1rem;
}

.wining-car {
  width: 80%;
  display: flex;
  gap: 1rem;
  background: #fff;
  padding: 1%;
  border: 1px solid #ddd;
  border-radius: 2px;
  margin: auto;
}

.wining-car span {
  border-bottom: 1px solid #d9d9d9;
  padding: 0.5rem 0;
  width: 80%;
}

.wining-car__img img {
  max-width: 250px;
  height: 100%;
  width: 100%;
}

.wining-car__content {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.wining-car__informations {
  display: flex;
  gap: 4rem;
}
.wining-car__informations-content {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.wining-car h2 {
  font-size: 20px;
  font-weight: 600;
}

.wining-car h6 {
  font-size: 14px;
}

.wining-car span {
  font-size: 15px;
}

.wining-car__actions {
  width: 20%;
  display: flex;
  flex-direction: column;
  gap: 10px;

  text-align: center;
  background: var(--primaryColor);
  color: #fff;
  padding: 1% 2%;
  border-radius: 5px;
}

.wining-car__actions h3 {
  font-size: 15px;
  font-weight: 700;
  border-bottom: 1px solid #fff;
  text-transform: uppercase;
  padding: 4%;
}
.wining-car__actions h6 {
  font-size: 14px;
  color: #f4c23d;
  font-weight: 500;
}

.wining-car__actions-content {
  display: flex;
  gap: 10px;
  align-items: center;
  padding: 4% 0;
}

.actions-line {
  width: 100%;
  height: 1px;
  background: #f4c23d;
}

.actions__icon {
  stroke: #f4c23d;
  fill: #f4c23d;

  box-shadow: rgba(244, 194, 61, 0.12) 0px 2px 4px 0px,
    rgba(244, 194, 61, 0.32) 0px 2px 16px 0px;
  border-radius: 50%;
}

.payment-and-documents {
  width: 100%;
  margin-top: 3rem;
}

.payment-and-documents__nav {
  display: flex;
  justify-content: center;
  gap: 1rem;
  padding: 1%;
}

.payment-and-documents__nav-link {
  font-size: 14px;
  color: #222222;
  text-transform: uppercase;
  font-weight: 700;
  text-decoration: none;
  position: relative;
  padding-bottom: 3px;
}

.payment-and-documents__nav-link:hover {
  color: #222222;
}

.payment-and-documents__nav-link:before,
.payment-and-documents__nav-link:after {
  position: absolute;
  transition: all 0.35s ease;
}
.payment-and-documents__nav-link:before {
  bottom: 0;
  display: block;
  height: 3px;
  width: 0%;
  content: "";
  background-color: #f4c23d;
}

.payment-and-documents__nav-link.active::before {
  opacity: 1;
  width: 100%;
}

.payment-and-documents__nav-link:after {
  left: 0;
  top: 0;
  padding: 0.5em 0;
  position: absolute;
  content: attr(data-hover);
  color: #ffffff;
  white-space: nowrap;
  max-width: 0%;
  overflow: hidden;
}
.payment-and-documents__nav-link:hover:before {
  opacity: 1;
  width: 100%;
}
.payment-and-documents__nav-link:hover:after {
  max-width: 100%;
}

.payment {
  width: 80%;
  margin: auto;
  background: #fff;
  margin-bottom: 3rem;
  box-shadow: 0 4px 24px 0 rgba(34, 41, 47, 0.1);
}

.payment__content {
  display: flex;
  padding: 1.3% 1%;
  font-size: 14px;
  border-bottom: 1px solid #ddd;
}

.payment__content--navbar {
  background: var(--primaryColor);
  color: #fff;
}

.payment__content h4 {
  width: 50%;
}

.payment__content h6 {
  width: 16.6%;
}

.payment__content--done {
  background: #f9f9f9;
}

.payment__content-bold-heading {
  color: #2b2b2b;
  font-weight: 600;
}

.documents {
  width: 80%;
  margin: auto;
  background: #fff;
}

.documents__content {
  display: flex;
  padding: 1.3% 1%;
  font-size: 14px;
  border-bottom: 1px solid #ddd;
  justify-content: space-between;
}
.documents__content a {
  display: flex;
  align-items: center;
  gap: 10px;
  text-decoration: none;
  color: #767676;
}

.documents__content a:hover {
  text-decoration: underline;
  color: #2b2b2b;
}

.documents__content-icon {
  font-size: 20px;
  stroke: #d00;
  fill: #d00;
}

.received-cars-list__vehicle {
  width: 346px;
}

.cancelled-car__bid-text {
  font-size: 14px;
}

.received__type-of-auction {
  font-size: 20px;
}

.client-car {
  width: 100%;
  margin-top: 3rem;
  cursor: pointer;
}

@media (max-width: 1260px) {
  .received-cars-list {
    font-size: 12px;
    align-items: center;
  }

  .wining-car {
    width: 80% !important;
  }

  .received-cars-list__vehicle {
    width: 360px;
  }
  .cancelled-car__bid-text {
    font-size: 12px;
  }

  .received__type-of-auction {
    font-size: 18px;
  }

  .wining-car h2 {
    font-size: 15px;
  }
}

@media (max-width: 992px) {
  .wining-car__img img {
    max-width: 200px;
  }

  .wining-car span {
    font-size: 11px;
  }

  .wining-car h2 {
    font-size: 11px;
  }
  .wining-car h6 {
    font-size: 11px;
  }

  .wining-car__actions h3 {
    font-size: 11px;
  }

  .wining-car__actions h6 {
    font-size: 11px;
  }

  .payment__content {
    font-size: 11px;
  }

  .payment-and-documents__nav-link {
    font-size: 11px;
  }

  .documents__content {
    font-size: 11px;
  }

  .received-cars-list {
    text-align: center;
  }

  .cancelled-car {
    text-align: center;
  }

  .cancelled-car__image {
    text-align: initial;
  }

  .received-cars-list__vehicle {
    text-align: initial;
  }

  .documents__content a {
    font-size: 11px;
  }

  .client-car-container {
    width: 100%;
    overflow-x: scroll;
  }

  .client-car {
    width: 895px;
  }
}

@media (max-width: 767px) {
  .payment-and-documents {
    width: 100%;
    overflow-x: scroll;
  }
  .payment-and-documents__nav,
  .payment,
  .documents {
    width: 712px;
    margin: auto;
  }
}

@media (max-width: 576px) {
  .client-car-container {
    width: 90%;
    margin: auto;
  }

  .wining-car {
    width: 500px !important;
  }
  .payment-and-documents__nav,
  .payment,
  .documents {
    width: 600px;
  }

  .wining-car-section {
    width: 100%;
    overflow-x: scroll;
    display: block;
  }
  .payment-and-documents__nav {
    padding: 2%;
  }
}
