.login--padding {
  text-align: center;
  padding: 3%;
}

.back-to-login__link {
  color: var(--primaryColor);
  vertical-align: middle;
  text-decoration: none;
  display: flex;
  margin: auto;
  align-items: center;
  gap: 5px;
  font-size: 0.897rem;
}
