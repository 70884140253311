.invoices-table.table {
  width: 1558px;
}

.invoices-table.table.invoices {
  width: 100% !important;
}

.invoices-table.proforma-invoices .action-content {
  display: flex;
  align-items: center;
  gap: 0.3rem;
}

.table-striped {
  --bs-table-bg: #f8f8f8;
}
