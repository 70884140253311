.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.font-weight-light {
  font-weight: 300 !important;
}

.font-weight-normal {
  font-weight: 400 !important;
}

.font-weight-bold {
  font-weight: 700 !important;
}

.font-italic {
  font-style: italic !important;
}
.text-caption {
  font-size: 0.875rem;
}

.h2,
h2 {
  font-size: 2.25rem;
}
.mb-5,
.my-5 {
  margin-bottom: 2rem !important;
}

.bg-secondary-lighter {
  background-color: #edeff2 !important;
}

.badge-primary {
  background-color: #00965e;
  color: #fff;
}

.swiper-container {
  list-style: none;
  margin-left: auto;
  margin-right: auto;
  overflow: hidden;
  padding: 0;
  position: relative;
  z-index: 1;
}

.swiper-container-vertical > .swiper-wrapper {
  flex-direction: column;
}

.swiper-wrapper {
  box-sizing: content-box;
  display: flex;
  height: 100%;
  position: relative;
  transition-property: transform;
  width: 100%;
  z-index: 1;
  overflow: auto;
  transform: unset !important;
}

.swiper-container-android .swiper-slide,
.swiper-wrapper {
  transform: translateZ(0);
}

.swiper-container-multirow > .swiper-wrapper {
  flex-wrap: wrap;
}

.swiper-container-multirow-column > .swiper-wrapper {
  flex-direction: column;
  flex-wrap: wrap;
}

.swiper-container-free-mode > .swiper-wrapper {
  margin: 0 auto;
  transition-timing-function: ease-out;
}

.swiper-container-pointer-events {
  touch-action: pan-y;
}

.swiper-container-pointer-events.swiper-container-vertical {
  touch-action: pan-x;
}

.swiper-slide {
  flex-shrink: 0;
  height: 100%;
  position: relative;
  transition-property: transform;
  width: 100%;
}

.swiper-slide-invisible-blank {
  visibility: hidden;
}

.swiper-container-autoheight,
.swiper-container-autoheight .swiper-slide {
  height: auto;
}

.swiper-container-autoheight .swiper-wrapper {
  align-items: flex-start;
  transition-property: transform, height;
}

.swiper-container-3d {
  perspective: 1200px;
}

.swiper-container-3d .swiper-cube-shadow,
.swiper-container-3d .swiper-slide,
.swiper-container-3d .swiper-slide-shadow-bottom,
.swiper-container-3d .swiper-slide-shadow-left,
.swiper-container-3d .swiper-slide-shadow-right,
.swiper-container-3d .swiper-slide-shadow-top,
.swiper-container-3d .swiper-wrapper {
  transform-style: preserve-3d;
}

.swiper-container-3d .swiper-slide-shadow-bottom,
.swiper-container-3d .swiper-slide-shadow-left,
.swiper-container-3d .swiper-slide-shadow-right,
.swiper-container-3d .swiper-slide-shadow-top {
  height: 100%;
  left: 0;
  pointer-events: none;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 10;
}

.swiper-container-3d .swiper-slide-shadow-left {
  background-image: linear-gradient(270deg, rgba(0, 0, 0, 0.5), transparent);
}

.swiper-container-3d .swiper-slide-shadow-right {
  background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.5), transparent);
}

.swiper-container-3d .swiper-slide-shadow-top {
  background-image: linear-gradient(0deg, rgba(0, 0, 0, 0.5), transparent);
}

.swiper-container-3d .swiper-slide-shadow-bottom {
  background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.5), transparent);
}

.swiper-container-css-mode > .swiper-wrapper {
  -ms-overflow-style: none;
  overflow: auto;
  scrollbar-width: none;
}

.swiper-container-css-mode > .swiper-wrapper::-webkit-scrollbar {
  display: none;
}

.swiper-container-css-mode > .swiper-wrapper > .swiper-slide {
  scroll-snap-align: start start;
}

.swiper-container-horizontal.swiper-container-css-mode > .swiper-wrapper {
  -ms-scroll-snap-type: x mandatory;
  scroll-snap-type: x mandatory;
}

.swiper-container-vertical.swiper-container-css-mode > .swiper-wrapper {
  -ms-scroll-snap-type: y mandatory;
  scroll-snap-type: y mandatory;
}

:root {
  --swiper-navigation-size: 44px;
}

.swiper-button-next,
.swiper-button-prev {
  align-items: center;
  color: var(--swiper-navigation-color, var(--swiper-theme-color));
  cursor: pointer;
  display: flex;
  height: var(--swiper-navigation-size);
  justify-content: center;
  margin-top: calc(0px - var(--swiper-navigation-size) / 2);
  position: absolute;
  top: 50%;
  width: calc(var(--swiper-navigation-size) / 44 * 27);
  z-index: 10;
}

.swiper-button-next.swiper-button-disabled,
.swiper-button-prev.swiper-button-disabled {
  cursor: auto;
  opacity: 0.35;
  pointer-events: none;
}

.swiper-button-next:after,
.swiper-button-prev:after {
  font-family: swiper-icons;
  font-size: var(--swiper-navigation-size);
  font-variant: normal;
  letter-spacing: 0;
  line-height: 1;
  text-transform: none !important;
  text-transform: none;
}

.swiper-button-prev,
.swiper-container-rtl .swiper-button-next {
  left: 10px;
  right: auto;
}

.swiper-button-prev:after,
.swiper-container-rtl .swiper-button-next:after {
  content: "prev";
}

.swiper-button-next,
.swiper-container-rtl .swiper-button-prev {
  left: auto;
  right: 10px;
}

.swiper-button-next:after,
.swiper-container-rtl .swiper-button-prev:after {
  content: "next";
}

.swiper-button-next.swiper-button-white,
.swiper-button-prev.swiper-button-white {
  --swiper-navigation-color: #fff;
}

.swiper-button-next.swiper-button-black,
.swiper-button-prev.swiper-button-black {
  --swiper-navigation-color: #000;
}

.swiper-button-lock {
  display: none;
}

.swiper-pagination {
  position: absolute;
  text-align: center;
  transform: translateZ(0);
  transition: opacity 0.3s;
  z-index: 10;
}

.swiper-pagination.swiper-pagination-hidden {
  opacity: 0;
}

.swiper-container-horizontal > .swiper-pagination-bullets,
.swiper-pagination-custom,
.swiper-pagination-fraction {
  bottom: 10px;
  left: 0;
  width: 100%;
}

.swiper-pagination-bullets-dynamic {
  font-size: 0;
  overflow: hidden;
}

.swiper-pagination-bullets-dynamic .swiper-pagination-bullet {
  position: relative;
  transform: scale(0.33);
}

.swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active,
.swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-main {
  transform: scale(1);
}

.swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-prev {
  transform: scale(0.66);
}

.swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-prev-prev {
  transform: scale(0.33);
}

.swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-next {
  transform: scale(0.66);
}

.swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-next-next {
  transform: scale(0.33);
}

.swiper-pagination-bullet {
  background: #000;
  border-radius: 50%;
  display: inline-block;
  height: 8px;
  opacity: 0.2;
  width: 8px;
}

button.swiper-pagination-bullet {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  box-shadow: none;
  margin: 0;
  padding: 0;
}

.swiper-pagination-clickable .swiper-pagination-bullet {
  cursor: pointer;
}

.swiper-pagination-bullet:only-child {
  display: none !important;
}

.swiper-container-vertical > .swiper-pagination-bullets {
  right: 10px;
  top: 50%;
  transform: translate3d(0, -50%, 0);
}

.swiper-container-vertical
  > .swiper-pagination-bullets
  .swiper-pagination-bullet {
  display: block;
  margin: 6px 0;
}

.swiper-container-vertical
  > .swiper-pagination-bullets.swiper-pagination-bullets-dynamic {
  top: 50%;
  transform: translateY(-50%);
  width: 8px;
}

.swiper-container-vertical
  > .swiper-pagination-bullets.swiper-pagination-bullets-dynamic
  .swiper-pagination-bullet {
  display: inline-block;
  transition: transform 0.2s, top 0.2s;
}

.swiper-container-horizontal
  > .swiper-pagination-bullets
  .swiper-pagination-bullet {
  margin: 0 4px;
}

.swiper-container-horizontal
  > .swiper-pagination-bullets.swiper-pagination-bullets-dynamic {
  left: 50%;
  transform: translateX(-50%);
  white-space: nowrap;
}

.swiper-container-horizontal
  > .swiper-pagination-bullets.swiper-pagination-bullets-dynamic
  .swiper-pagination-bullet {
  transition: transform 0.2s, left 0.2s;
}

.swiper-container-horizontal.swiper-container-rtl
  > .swiper-pagination-bullets-dynamic
  .swiper-pagination-bullet {
  transition: transform 0.2s, right 0.2s;
}

.swiper-pagination-progressbar {
  background: rgba(0, 0, 0, 0.25);
  position: absolute;
}

.swiper-pagination-progressbar .swiper-pagination-progressbar-fill {
  background: var(--swiper-pagination-color, var(--swiper-theme-color));
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  transform: scale(0);
  transform-origin: left top;
  width: 100%;
}

.swiper-container-rtl
  .swiper-pagination-progressbar
  .swiper-pagination-progressbar-fill {
  transform-origin: right top;
}

.swiper-container-horizontal > .swiper-pagination-progressbar,
.swiper-container-vertical
  > .swiper-pagination-progressbar.swiper-pagination-progressbar-opposite {
  height: 4px;
  left: 0;
  top: 0;
  width: 100%;
}

.swiper-container-horizontal
  > .swiper-pagination-progressbar.swiper-pagination-progressbar-opposite,
.swiper-container-vertical > .swiper-pagination-progressbar {
  height: 100%;
  left: 0;
  top: 0;
  width: 4px;
}

.swiper-pagination-white {
  --swiper-pagination-color: #fff;
}

.swiper-pagination-black {
  --swiper-pagination-color: #000;
}

.swiper-pagination-lock {
  display: none;
}

.swiper-scrollbar {
  background: rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  position: relative;
  -ms-touch-action: none;
}

.swiper-container-horizontal > .swiper-scrollbar {
  bottom: 3px;
  height: 5px;
  left: 1%;
  position: absolute;
  width: 98%;
  z-index: 50;
}

.swiper-container-vertical > .swiper-scrollbar {
  height: 98%;
  position: absolute;
  right: 3px;
  top: 1%;
  width: 5px;
  z-index: 50;
}

.swiper-scrollbar-drag {
  background: rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  height: 100%;
  left: 0;
  position: relative;
  top: 0;
  width: 100%;
}

.swiper-scrollbar-cursor-drag {
  cursor: move;
}

.swiper-scrollbar-lock {
  display: none;
}

.swiper-zoom-container {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  text-align: center;
  width: 100%;
}

.swiper-zoom-container > canvas,
.swiper-zoom-container > img,
.swiper-zoom-container > svg {
  max-height: 100%;
  max-width: 100%;
  -o-object-fit: contain;
  object-fit: contain;
}

.swiper-slide-zoomed {
  cursor: move;
}

.swiper-lazy-preloader {
  -webkit-animation: swiper-preloader-spin 1s linear infinite;
  animation: swiper-preloader-spin 1s linear infinite;
  border: 4px solid var(--swiper-preloader-color, var(--swiper-theme-color));
  border-radius: 50%;
  border-top: 4px solid transparent;
  box-sizing: border-box;
  height: 42px;
  left: 50%;
  margin-left: -21px;
  margin-top: -21px;
  position: absolute;
  top: 50%;
  transform-origin: 50%;
  width: 42px;
  z-index: 10;
}

.swiper-lazy-preloader-white {
  --swiper-preloader-color: #fff;
}

.swiper-lazy-preloader-black {
  --swiper-preloader-color: #000;
}

@keyframes swiper-preloader-spin {
  to {
    transform: rotate(1turn);
  }
}

.swiper-container .swiper-notification {
  left: 0;
  opacity: 0;
  pointer-events: none;
  position: absolute;
  top: 0;
  z-index: -1000;
}

.swiper-container-fade.swiper-container-free-mode .swiper-slide {
  transition-timing-function: ease-out;
}

.swiper-container-fade .swiper-slide {
  pointer-events: none;
  transition-property: opacity;
}

.swiper-container-fade .swiper-slide .swiper-slide {
  pointer-events: none;
}

.swiper-container-fade .swiper-slide-active,
.swiper-container-fade .swiper-slide-active .swiper-slide-active {
  pointer-events: auto;
}

.swiper-container-cube {
  overflow: visible;
}

.swiper-container-cube .swiper-slide {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  height: 100%;
  pointer-events: none;
  transform-origin: 0 0;
  visibility: hidden;
  width: 100%;
  z-index: 1;
}

.swiper-container-cube .swiper-slide .swiper-slide {
  pointer-events: none;
}

.swiper-container-cube.swiper-container-rtl .swiper-slide {
  transform-origin: 100% 0;
}

.swiper-container-cube .swiper-slide-active,
.swiper-container-cube .swiper-slide-active .swiper-slide-active {
  pointer-events: auto;
}

.swiper-container-cube .swiper-slide-active,
.swiper-container-cube .swiper-slide-next,
.swiper-container-cube .swiper-slide-next + .swiper-slide,
.swiper-container-cube .swiper-slide-prev {
  pointer-events: auto;
  visibility: visible;
}

.swiper-container-cube .swiper-slide-shadow-bottom,
.swiper-container-cube .swiper-slide-shadow-left,
.swiper-container-cube .swiper-slide-shadow-right,
.swiper-container-cube .swiper-slide-shadow-top {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  z-index: 0;
}

.swiper-container-cube .swiper-cube-shadow {
  bottom: 0;
  height: 100%;
  left: 0;
  opacity: 0.6;
  position: absolute;
  width: 100%;
  z-index: 0;
}

.swiper-container-cube .swiper-cube-shadow:before {
  background: #000;
  bottom: 0;
  content: "";
  filter: blur(50px);
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}

.swiper-container-flip {
  overflow: visible;
}

.swiper-container-flip .swiper-slide {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  pointer-events: none;
  z-index: 1;
}

.swiper-container-flip .swiper-slide .swiper-slide {
  pointer-events: none;
}

.swiper-container-flip .swiper-slide-active,
.swiper-container-flip .swiper-slide-active .swiper-slide-active {
  pointer-events: auto;
}

.swiper-container-flip .swiper-slide-shadow-bottom,
.swiper-container-flip .swiper-slide-shadow-left,
.swiper-container-flip .swiper-slide-shadow-right,
.swiper-container-flip .swiper-slide-shadow-top {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  z-index: 0;
}

:root {
  --swiper-theme-color: #00965e;
  --swiper-navigation-color: #172328;
  --swiper-navigation-size: 1rem;
}

.swiper-pagination-bullet {
  background: #00965e;
  opacity: 0.5;
}

.swiper-pagination-bullet-active {
  background: var(--swiper-pagination-color, var(--swiper-theme-color));
  opacity: 1;
}

.swipper-mega-button.swiper-button-next,
.swipper-mega-button.swiper-button-prev {
  bottom: 0;
  cursor: pointer !important;
  height: 100%;
  margin-top: 0;
  pointer-events: auto !important;
  top: 0;
  width: 33%;
}

.swipper-mega-button.swiper-button-prev {
  left: 0;
}

.swipper-mega-button.swiper-button-next {
  right: 0;
}

.swipper-mega-button.swiper-button-next:hover,
.swipper-mega-button.swiper-button-prev:hover {
  opacity: 0.2;
}

.swipper-mega-button.swiper-button-prev:hover {
  background: linear-gradient(-90deg, hsla(0, 0%, 100%, 0), #fff 63%);
}

.swipper-mega-button.swiper-button-next:hover {
  background: linear-gradient(90deg, hsla(0, 0%, 100%, 0), #fff 63%);
}

.swipper-mega-button.swiper-button-next:after,
.swipper-mega-button.swiper-button-prev:after {
  content: none;
}

.gallery-thumbs .swiper-slide {
  height: 100%;
  opacity: 0.4;
}

.gallery-thumbs .swiper-slide-thumb-active {
  opacity: 1;
}

.back-to-top {
  align-items: center;
  background-color: #172328;
  border: 1px solid #edeff2;
  border-radius: 3rem;
  cursor: pointer;
  display: flex;
  height: 3.125rem;
  justify-content: center;
  opacity: 0.35;
  width: 3.125rem;
}

.text-secondary-lighter {
  color: #edeff2 !important;
}

.mt-2,
.my-2 {
  margin-top: 0.5rem !important;
}

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important;
}

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 500 !important;
  line-height: 1.2 !important;
  margin-bottom: 0.5rem !important;
}

.text-secondary-darker {
  color: #5c6166 !important;
}

.text-caption {
  font-size: 0.875rem;
}

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important;
}

.cursor-pointer {
  cursor: pointer;
}
