.contact-form__textarea {
  width: auto;
  height: 183px;
  border: 1px solid #d8d6de;
  max-width: 100%;
  min-height: 15rem;
  max-height: 20rem;
  font-size: 1rem;
  color: #6e6b7b;
  -webkit-text-fill-color: #6e6b7b;
  opacity: 1;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding: 12px;
  outline: none;
  resize: vertical;
  border-radius: 0.357rem;
  line-height: 1.4;
}

textarea:disabled {
  cursor: default;
  background-color: transparent;
  color: -internal-light-dark(rgb(84, 84, 84), rgb(170, 170, 170));
  -webkit-text-fill-color: -internal-light-dark(
    rgb(84, 84, 84),
    rgb(170, 170, 170)
  );
  opacity: 1;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-color: rgba(118, 118, 118, 0.3);
}

.delete-button {
  background: transparent;
  color: #d00;
  border-color: #d00;
}

.delete-button:hover {
  background: #d00;
  color: #fff;
  box-shadow: none;
}

.contact-actions {
  width: 18%;
}

@media (max-width: 992px) {
  .contact-form__textarea {
    font-size: 15px;
  }
}

@media (max-width: 576px) {
  .contact-form__textarea {
    font-size: 13px;
  }
}
