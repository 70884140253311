.change-password__paragraph {
  font-size: 1rem;
  font-weight: 600;
  color: #6e6b7b;
  padding: 0 1.5%;
}

.change-password__list {
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.45;
  color: #6e6b7b;
  padding: 0px 2.7%;
  list-style: unset;
}

.input-error {
  display: flex;
  flex-direction: column;
  gap: 6px;
}

.error-message {
  -webkit-animation: moveInBottom 0.3s ease-in-out;
          animation: moveInBottom 0.3s ease-in-out;
  font-size: 11px;
  color: #d00;
}

.change-pw--width{
  width: calc(50% - 1.5rem);
}

@media (max-width: 1200px) {
  .change-password__paragraph {
    font-size: 0.8rem;
  }

  .change-password__list{
    font-size: 0.7rem;
  }
}

@media (max-width: 576px) {

  .change-password__paragraph{
    width: 90%;
    margin: auto;
    padding: 0 2.5%;
    font-size: 13px;
  }
  .change-password__list{
    width: 90%;
    margin: auto;
    padding: 0px 4.6%;
    font-size: 11px;
  }

  .change-pw--width{
    width: 90%;
    margin: auto;
  }
}