.car-list {
  width: 100%;
  display: flex;
  border: 1px solid #eeeeee;
  transition: all 0.3s;
}

.car-list:hover {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 50px;
  transition: all 0.3s;
}

.car-list__image {
  display: flex;
  cursor: pointer;
}

.car-list__image img {
  max-width: 24rem;
}

.car-list-content {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 12px;
}

.car-list-content__paragraph {
  display: flex;
  flex-direction: column;
  padding-left: 5%;
  gap: 10px;
}

.car-list-content__paragraph p {
  width: 95%;
  font-size: 13px;
  line-height: 22px;
  color: #888888;
}

.car-list-content__paragraph button,
.confirm-btn {
  font-size: 12px;
  font-family: "Montserrat", sans-serif;
  color: var(--primaryColor);
  text-transform: uppercase;
  border: 1px solid #eeeeee;
  padding: 5px 8px;
  width: 20%;
  outline: none;
  background: transparent;
  transition: all 0.3s;
}

.car-list-content__paragraph button:hover,
.confirm-btn:hover {
  font-weight: 600;
  border: 1px solid #a7a7a7;
  transition: all 0.3s;
  cursor: pointer;
}

.car-list-content__line {
  width: 100%;
  height: 1px;
  background: #eeeeee;
}

.car-list-content__name {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.car-list-content__name h3 {
  text-transform: uppercase;
  color: #222222;
  font-weight: 700;
  padding-left: 5%;
  cursor: pointer;
  padding-top: 1rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 33rem;
  text-align: start;
}

.car-list-content__fav-icon {
  font-size: 20px;
  padding: 17px;
  border-bottom: 1px solid #eee;
  border-left: 1px solid #eee;
  cursor: pointer;
}
.filled {
  stroke: #f4c23d;
  fill: #f4c23d;
}

.car-list-content__type {
  border: 1px solid #eeeeee;
  display: flex;
  justify-content: space-around;
  background-color: #f5fafe;
  padding: 1.5%;
}

.type {
  display: flex;
  align-items: center;
  gap: 5px;
}

.type span {
  font-size: 13px;
  color: #888888;
}

.type__line {
  width: 1px;
  height: 100%;
  background: #888888;
}

.paragraph-content {
  display: flex;
  align-items: center;
  gap: 10px;
}

.paragraph-content__image {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  padding-right: 1rem;
  gap: 1.2rem 0;
}

.paragraph-content__image > span {
  font-size: 14px;
  font-weight: 600;
  color: var(--primaryColor);
}

.paragraph-content__image img {
  width: 25px;
}

.paragraph-content__text {
  font-size: 14px;
  color: #888888;
}

.paragraph-content__text span {
  font-weight: 700;
}

.paragraph-content__image .starting-price {
  padding: 0;
  flex-direction: row;
  font-weight: unset;
  font-size: 0.875rem;
  color: #5c616b !important;
}

.paragraph-content__image .starting-price span {
  font-size: unset;
}

@media (max-width: 1260px) {
  .car-list-content__name {
    align-items: center;
  }

  .car-list-content__name h3 {
    margin-top: 0;
  }

  .car-list-content__fav-icon {
    padding: 15px;
  }

  .car-list-content__paragraph button,
  .confirm-btn {
    width: 25%;
  }

  .paragraph-content__image img {
    width: 20px;
  }

  .type svg {
    font-size: 13px;
  }
}

@media (max-width: 1000px) {
  .car-list {
    flex-direction: column;
    max-width: 30rem;
    gap: 0;
  }

  .car-list__image img {
    max-width: none;
    width: 100%;
  }

  .car-list__image img {
    width: 100%;
  }

  .car-list-content__paragraph button,
  .confirm-btn {
    width: 35%;
  }

  .type__line {
    display: none;
  }

  .car-list-content__type {
    justify-content: space-between;
    padding: 2%;
  }

  .car-list-content {
    gap: 15px;
  }
}

@media (max-width: 600px) {
  .car-list-content__type {
    flex-wrap: wrap;
    justify-content: center;
    gap: 1rem;
  }

  .car-list-content__paragraph {
    padding: 0 5%;
  }

  .car-list-content__paragraph button,
  .confirm-btn {
    width: 100%;
    padding: 8px;
  }
  .car-list-content__name h3 {
    width: 80%;
    font-size: 14px;
  }
  .car-list-content__fav-icon {
    font-size: 17px;
  }
  .paragraph-content__text {
    font-size: 13px;
  }
  .car-list-content__paragraph p {
    width: auto;
    font-size: 12px;
  }
}

.car-list-content-registation {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: 1rem;
}

.car-list-content-registation > span {
  font-size: 13px;
  font-weight: 500;
  color: gray;
}

.car-list-content-registation > span:first-child {
  border: 1px solid #80808091;
  padding: 5px 15px;
  border-radius: 5px;
}

.car-list-content-registation .car-list-clock-down,
.car-details-container__header .car-list-clock-down {
  display: flex;
  align-items: center;
  gap: 0.2rem;
  color: #336699;
  font-size: 15px;
  font-weight: 500;
}

.car-list-content-registation .car-list-clock-down svg,
.car-details-container__header .car-list-clock-down svg {
  height: 18px;
  width: 18px;
}

.car-details-container__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.car-list-clock-down span {
  display: flex;
  align-items: center;
}

.car-list-clock-down.blue-clockdown-timer {
  color: #336699;
}

.car-list-clock-down.green-clockdown-timer {
  color: #60ad25;
}

.car-list-clock-down.orange-clockdown-timer {
  color: #e68217;
}

.car-list-clock-down.red-clockdown-timer {
  color: red;
}
