.profile-details {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  background: #fff;
  box-shadow: 0 4px 24px 0 rgba(34, 41, 47, 0.1);
}

.profile-details__title {
  border-bottom: 1px solid #eee;
  padding: 1.5%;
}
.profile-details__title h2 {
  color: #5e5873;
  font-weight: 500;
  font-size: 1.285rem;
}

.profile-details__inputs {
  display: flex;
  flex-direction: column;
  gap: 1.53rem;
  padding: 1.5%;
}
.profile-details__input-content {
  display: flex;
  justify-content: space-between;
  gap: 3rem;
  align-items: center;
}

.profile-details__input-content div {
  width: 100%;
}

.profile-details__buttons {
  display: flex;
  gap: 1rem;
  padding: 1.5%;
}

.save-changes {
  padding: 0.786rem 1.5rem;
  font-size: 1rem;
  border: 1px solid var(--primaryColor);
  line-height: 1;
  background: var(--primaryColor);
  color: #fff;
  font-family: inherit;
  border-radius: 0.358rem;
  outline: none;
  cursor: pointer;
  transition: all 0.15s ease-in-out;
}

.save-changes:hover {
  box-shadow: 0 8px 25px -8px var(--primaryColor);
  transition: all 0.15s ease-in-out;
}

.save-changes:active {
  box-shadow: none;
  transition: all 0.15s ease-in-out;
}

.discard {
  padding: 0.786rem 1.5rem;
  font-size: 1rem;
  border: 1px solid #82868b;
  line-height: 1;
  background: transparent;
  font-family: inherit;
  border-radius: 0.358rem;
  outline: none;
  cursor: pointer;
  transition: all 0.15s ease-in-out;
  color: #82868b;
}
.discard:hover {
  background-color: rgba(130, 134, 139, 0.2);
  transition: all 0.15s ease-in-out;
}

.error-border {
  border-radius: 7px;
}

@media (max-width: 992px) {
  .profile-details__title {
    padding: 2.5% 1.5%;
  }

  .save-changes {
    font-size: 15px;
  }
  .discard {
    font-size: 15px;
  }
}

@media (max-width: 576px) {
  .profile-details__input-content {
    gap: 1rem;
    flex-direction: column;
    width: 90% !important;
    margin: auto;
  }

  .profile-details__title {
    text-align: center;
    padding: 5% 2.5%;
  }

  .profile-details__inputs {
    padding: 2.5%;
  }

  .profile-details__buttons {
    padding: 3.5% 2.5% 10%;
    margin: auto;
  }

  .save-changes {
    font-size: 13px;
  }
  .discard {
    font-size: 13px;
  }
}
