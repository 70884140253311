.invoice-modal .ant-modal-title {
  text-align: center;
  margin: 0;
}

.invoice-date {
  display: flex;
  justify-content: end;
  letter-spacing: 0.05rem;
  padding: 0.5rem;
  font-weight: 500;
}

.invoice-clientinfo {
  border-top: 1px solid;
  display: flex;
  /* justify-content: space-between; */
  flex-wrap: wrap;
  padding-top: 1rem;
  gap: 5.5rem;
}

.invoice-clientinfo h3,
.invoice-info h3 {
  font-weight: 500;
  font-size: 15px;
  margin-top: 0.5rem;
  color: black;
}

.invoice-info {
  margin-top: 1rem;
}

.invoice-modal .invoice-downloadbtn {
  display: flex;
  align-items: center;
  gap: 0.4rem;
  float: right;
  margin-left: 0.5rem;
}

.invoice-modal .ant-modal-title img {
  height: 40px;
}

.invoice-modal .ant-modal-title > div:first-child div {
  font-weight: 600;
  display: flex;
  flex-direction: column;
  text-align: start;
}

.invoice-modal .ant-modal-title > div:first-child span {
  font-size: 1.1rem;
}

.invoice-modal .ant-modal-title > div:first-child {
  display: flex;
  justify-content: space-between;
}

.invoice-modal .invoice-table tr:last-child {
  background-color: #1a344e0a;
}

.invoice-modal .ant-modal-footer {
  display: flex;
  align-items: center;
  justify-content: end;
  margin-top: 1.2rem;
}

.invoice-modal .client-contactinfo {
  display: flex;
  flex-direction: column;
  gap: 1.8rem;
}

.invoice-modal.ant-modal .ant-modal-content {
  padding: 5% 5% 2% 5% !important;
}

.invoice-modal {
  width: 600px !important;
}

.invoice-modal .ant-table-wrapper {
  width: 100%;
}

.receive-confirmation-modal,
.invoice-confirmation-modal {
  text-align: center !important;
}

.receive-confirmation-modal .ant-modal-title,
.invoice-confirmation-modal .ant-modal-title {
  display: flex;
  align-items: center;
  gap: 0.4rem;
  flex-direction: column;
}

.receive-confirmation-modal .ant-modal-title svg,
.invoice-confirmation-modal .ant-modal-title svg {
  font-size: 5rem;
  color: #ff170066;
}

.receive-confirmation-modal .ant-btn-primary,
.invoice-confirmation-modal .ant-btn-primary {
  background-color: #2e465d !important;
}

.invoice-confirmation-modal .ant-modal-body {
  max-width: 560px;
}

.client-car-info {
  display: flex;
  gap: 3rem;
  margin-top: 1.5rem;
}

.car-details-info-about-car.client-info,
.car-details-info-about-car.car-info {
  flex: 1;
}

.car-details-info-about-car.client-info h1,
.car-details-info-about-car.car-info h1 {
  font-weight: 600 !important;
}

.create-invoice-modal.ant-modal {
  width: 810px !important;
}

.create-invoice-modal.ant-modal.new-invoice {
  width: 900px !important;
}

.invoiceLines-inputs {
  max-height: 300px;
  overflow: auto;
}

.total-price {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}

.total-price p {
  display: flex;
  gap: 0.6rem;
  font-size: 0.9rem;
  align-items: baseline;
}

.total-price p > span {
  font-weight: 500;
  color: var(--primaryColor);
  font-size: 1.2rem;
}

.invoicemodal.create-serviceinvoice {
  width: 450px !important;
}

.ant-modal-content .ant-spin {
  color: var(--primaryColor);
}

.invoiceLines-inputs .form-input {
  gap: 0 5px;
}

.invoiceLines-inputs .input-width {
  margin-bottom: auto;
}
